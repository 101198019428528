$gb_width: 1920; //1920是设计图(画布)的宽度，可根据需要调整
// 响应式布局页面
@function pxtovw($size) {
    @return $size / $gb_width * 100vw;
}

.home-portal {
    overflow: auto;
    height: 100vh;
    background: url("../../assest/images/home-bg.jpg") no-repeat;
    background-size: cover;
    .header {
        padding: 0 10px;
        display: flex;
        height: 48px;
        background: #2F343E;
        color: #fff;
        align-items: center;
        .left {
            font-size: 16px;
        }
        .right {
            margin-left: auto;
            .avatar {
                img {
                    height: 32px;
                    width: 32px;
                    border-radius: 50%;
                }
            }
        }
    }
    .content {
        height: calc(100vh - 70px);
        padding: 30px;
        position: relative;
        &.platform-content {
            background: none;
        }
        .title {
            font-size: 14px;
            font-weight: bold;
            margin: 2px 0 12px;
            color: #fff;
        }
        .nav-top {
            height: 10vw;
            @media (min-width: 1900px) {
                height: 8.8vw;
            }
            .title {
              font-size: pxtovw(28);
            }
        }
        .nav-list {
            display: flex;
            padding: 0;
            list-style: none;
            flex-wrap: wrap;
            li {
                height: pxtovw(120);
                width: pxtovw(284);
                background: rgba(16,67,94,0.6);
                margin-right: pxtovw(20);
                // margin-bottom: pxtovw(20);
                font-size: pxtovw(14);
                padding: pxtovw(32) pxtovw(34);
                position: relative;
                display: flex;
                align-items: center;
                flex: 1;
                @media (min-width: 1900px) {
                    height: 6vw;
                }
                .top {
                    height: pxtovw(30);
                    width: 100%;
                    // background: url("../../assest/images/nav-title-bg.png") no-repeat;
                    // background-size: cover;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
                .nav-img {
                    width: pxtovw(52);
                    height: pxtovw(52);
                }
                .nav-title {
                    font-size: pxtovw(22);
                    color: #fff;
                    cursor: pointer;
                    flex: 1;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    padding-left: pxtovw(20);
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        .nav-box {
            display: flex;
            // margin-top: 30px;
            position: relative;
            padding-bottom: pxtovw(30);
            min-height: pxtovw(360);
            &-pb {
                padding-bottom: calc(30px + 10vw);
            }
            @media (min-width: 1900px) {
                &-pb {
                    padding-bottom: calc(30px + 8.8vw);
                }
            }
            > div:first-child {
                margin-right: pxtovw(30);
            }
            .left,
            .right {
                width: pxtovw(915);
                .title {
                    font-size: pxtovw(28)
                }
            }

            .menu-box {
                height: pxtovw(200);
                background: rgba(16,67,94,0.6);
                display: flex;
                margin-bottom: pxtovw(20);
                position: relative;
                @media (min-width: 1900px) {
                    height: 10vw;
                }
                .left-bg {
                    background: url("../../assest/images/card-left.png") no-repeat;
                    background-size: cover;
                    height: 100%;
                    width: pxtovw(50);
                }
                .right-bg {
                    height: 100%;
                    width: pxtovw(420);
                    position: absolute;
                    right: 0;
                    top: 0;
                    &.huiju {
                        background: url("../../assest/images/huiju.png") no-repeat;
                        background-size: cover;
                    }
                    &.bi {
                        background: url("../../assest/images/bi.png") no-repeat;
                        background-size: cover;
                    }
                    &.max {
                        background: url("../../assest/images/max.png") no-repeat;
                        background-size: cover;
                    }
                    &.report {
                        background: url("../../assest/images/report.png") no-repeat;
                        background-size: cover;
                    }
                    &.share {
                        background: url("../../assest/images/share.png") no-repeat;
                        background-size: cover;
                    }
                    &.dev {
                        background: url("../../assest/images/dev.png") no-repeat;
                        background-size: cover;
                    }
                }

                .menu {
                    margin-left: pxtovw(20);
                    width: pxtovw(200);
                    height: pxtovw(40);
                    line-height: pxtovw(40);
                    background: url("../../assest/images/card-title.png") no-repeat;
                    background-size: cover;
                    margin-top: pxtovw(32);
                    text-align: center;
                    color: #fff;
                    font-size: pxtovw(26)
                }
                .sub-menu-box {
                    margin-top: pxtovw(32);
                    z-index: 1;
                    width: pxtovw(470);
                    &.one-line {
                        width: pxtovw(175);
                    }
                }
                .sub-menu {
                    color: #fff;
                    display: inline-block;
                    width: pxtovw(235);
                    font-size: pxtovw(22);
                    height: pxtovw(30);
                    line-height: pxtovw(30);
                    align-items: center;
                    padding-left: pxtovw(20);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;
                    span:before {
                        content: "";
                        display: inline-block;
                        width: pxtovw(6);
                        height: pxtovw(6);
                        background: #fff;
                        margin-right: pxtovw(8);
                        vertical-align: middle;
                    }
                    &-mt {
                        margin-top: pxtovw(18);
                    }
                }
            }

            .content-bottom {
                position: absolute;
                bottom: pxtovw(10);
                left: 0;
                height: pxtovw(20);
                width: 100%;
                background: url("../../assest/images/content-bottom.png") no-repeat;
                background-size: cover;
            }
        }
    }
    .platform {
        display: flex;
        width: 1200px;
        height: 500px;
        justify-content: space-around;
        margin: 79px auto 0 auto;
        .product {
            width: 390px;
            height: 500px;
            border: 1px solid #eee;
            box-shadow: 0px 6px 16px 0px rgba(45, 83, 52, 0.1);
            border-radius: 2px;
            .top {
                background: #f8faf9;
                position: relative;
                overflow: hidden;
                padding: 10px 0;
                .text-choose {
                    color: #fff !important;
                }
                img {
                    position: absolute;
                    width: 121px;
                    height: 121px;
                    right: 0;
                }
                .title {
                    width: 244px;
                    height: 41px;
                    font-size: 34px;
                    color: #333333;
                    line-height: 41px;
                    margin-top: 25px;
                    margin-left: 25px;
                }
                .sub-title {
                    width: 250px;
                    height: 19px;
                    font-size: 14px;
                    color: #666666;
                    line-height: 19px;
                    margin-top: 12px;
                    margin-left: 25px;
                }
            }
            .top-choose {
                background: #21ac5f;
            }
            .img {
                .bottom {
                    height: 390px;
                    position: relative;
                    .bottom-img {
                        width: 309px;
                        height: 177px;
                        margin-left: 40px;
                        margin-top: 51px;
                        margin-bottom: 80px;
                    }
                    a {
                        color: #333;
                        text-decoration: none;
                    }
                }
            }
            .txt {
                .bottom {
                    height: 390px;
                    position: relative;
                    .title {
                        margin: 0;
                        padding: 0;
                        margin-left: 25px;
                        padding-top: 30px;
                        box-sizing: border-box;
                        .header {
                            width: 4px;
                            height: 4px;
                            border-radius: 50%;
                            background: #21ac5f;
                            margin-top: 7.5px;
                            padding: 0;
                        }
                        .text {
                            width: 64px;
                            height: 21px;
                            font-size: 16px;
                            color: #333333;
                            line-height: 21px;
                            margin-left: 3px;
                        }
                    }
                    .sub-title {
                        margin: 0;
                        padding: 0;
                        margin-left: 25px;
                        padding-top: 16px;
                        box-sizing: border-box;
                    }
                    .bottom-text {
                        width: 333px;
                        height: 44px;
                        font-size: 14px;
                        color: #666666;
                        line-height: 22px;
                        margin-top: 7px;
                        margin-left: 35px;
                    }
                }
                .bottom-choose {
                    background: linear-gradient(133deg, #ffffff 0%, #dfefe6 100%);
                    box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.1);
                }
            }
        }
        .display-none {
            display: none;
        }
        .flexW {
            display: flex;
            flex-wrap: wrap;
        }
    }
    .button {
        width: 340px;
        height: 40px;
        background: #21ac5f;
        border-radius: 2px;
        text-align: center;
        line-height: 40px;
        color: #fff;
        margin-left: 25px;
        position: absolute;
        bottom: 25px;
        font-weight: 500;
    }
    .platform-admin {
        margin: 20px auto 0 auto;
        display: flex;
        width: 1200px;
        justify-content: space-around;
        .button-box {
            width: 390px;
            .button {
                position: static;
            }
        }
    }
}
.header-menu {
    margin-top: 10px !important;
    width: 135px;
    .ant-dropdown-menu-title-content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
