.user-login {
  width: 100%;
  height: 100%;
  position: relative;
  background: linear-gradient(180deg, #272c38 20%, #181e25);
  .bg {
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
    > div {
      background-size: cover;
      position: absolute;
      transition: transform 0.05s;
      &.circle0,
      &.circle1,
      &.circle2 {
        background-image: url("../../assest/images/login/circle.png");
      }
      &.circle3,
      &.circle4,
      &.circle5 {
        background-image: url("../../assest/images/login/circle2.png");
      }
      &.circle6,
      &.circle7,
      &.circle8 {
        background-image: url("../../assest/images/login/circle3.png");
      }
      &.circle9,
      &.circle10,
      &.circle11 {
        background-image: url("../../assest/images/login/circle4.png");
      }
      &.circle12,
      &.circle13,
      &.circle14 {
        background-image: url("../../assest/images/login/circle5.png");
      }
    }
  }
  .home-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    .home-item {
      width: 360px;
      height: 340px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      margin: 0 40px;
      .title {
        width: 100%;
        text-align: center;
        color: #f6f6f6;
        font-size: 28px;
        font-weight: 500;
        padding: 0 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding: 0 10px;
      }
      .home-btn {
        width: 140px;
        height: 46px;
        outline: none;
        border: none;
        color: #fff !important;
        background: #23c312 !important;
        margin-top: 36px;
        font-size: 20px;
        cursor: pointer;
        border-radius: 4px;
        font-weight: 500;
      }
      .home-entrance {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
