.main-portal {
    display: flex;
    .nav {
        margin-right: -1px;
        .ant-menu {
            width: 60px;
            position: relative;
            height: 100vh;
            padding-top: 48px;
            > li.ant-menu-item:first-child {
                margin-top: 0;
            }
            > li.ant-menu-item:last-child {
                position: absolute;
                bottom: 0;
                width: 100%;
            }
            .ant-menu-item{
                color: #fff;
            }
            background: #2E6ACB url('../../assest/images/bg-left.jpg') no-repeat  !important;
            background-size: cover;
            .ant-menu-item.ant-menu-item-selected {
                background-color: rgba(255,255,255,0.2) !important;
            }
        }
        color: #fff;
        .ant-menu-item-icon {
            width: 20px !important;
        }
    }
    .content {
        height: 100vh;
        overflow: hidden;
        position: absolute;
        left: 59px;
        > iframe {
            width: calc(100vw - 59px);
        }
    }
}
.pro-submenu {
    .ant-menu-item:hover, .ant-menu-submenu-active, .ant-menu-item-selected, .ant-menu-submenu-title:hover {
        color: #333333;
        background: #ECEFF4;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
        background: #ECEFF4;
    }
}
// 这里是因为无法给menu增加 warpClassName
.ant-tooltip-inner{
    color: rgba(0,0,0,0.75);
    background-color: #fff
}
.ant-tooltip-arrow:before{
    background-color: #fff
}
.ant-tooltip-arrow-content {
    --antd-arrow-background-color: linear-gradient(to right bottom, rgba(255, 255, 255, .65), rgba(255, 255, 255, .75));
}
.ant-menu-inline-collapsed-tooltip:not(.ant-tooltip-hidden) {
    left: 48px !important;
}