@import '~antd/dist/antd.css';
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F2F5F7;
  height: 100vh;
  font-size: 14px;
}
#root {
  height: 100vh;
}